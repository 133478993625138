.error-page{
	.error-text {
		font-size: 150px;
		line-height: 1;
	}
	h4{
		font-size: 40px;
		margin-bottom: 5px;
	}
	p{
		font-size: 16px;
	}
}