html[dir="rtl"] {
  [direction="rtl"] {
    .footer {
      padding-#{$dlab-pos-right}: 17.1875rem;
      padding-#{$dlab-pos-left}: 0;

      @include media-breakpoint-down(sm) {
        padding-#{$dlab-pos-right}: 0;
      }
    }

    &[data-sidebar-style="overlay"] {
      .footer {
        padding-#{$dlab-pos-right}: 0;
      }
    }

    .menu-toggle {
      .footer {
        padding-#{$dlab-pos-right}: 3.75rem;
      }
    }

    &[data-container="boxed"] {
      .footer {
        padding-#{$dlab-pos-right}: 0;
      }
    }

    &[data-sidebar-style="mini"]:not([data-layout="horizontal"]) {
      .footer {
        padding-#{$dlab-pos-right}: 3.75rem;
      }
    }

    &[data-sidebar-style="compact"]:not([data-layout="horizontal"]) {
      .footer {
        padding-#{$dlab-pos-right}: 9.375rem;
      }
    }
  }
}
