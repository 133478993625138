

@include custommq($min: 48rem) {

    [data-sidebar-style="mini"] {
        
		--dz-sidebar-width : 6rem;
		--dz-sidebar-pos : absolute;
		--dz-side-nav-padding : 1.125rem 0.875rem;
		--dz-side-info : none;
		--dz-side-copyright : none;

        .nav-header {
            .nav-control {
				display: none;
            }
            .brand-title {
                display: none;
            }
			.brand-logo{
				justify-content: center;
			}
        }
		.header .header-content{
			padding-#{$dlab-pos-left}: 1.875rem;
		}
        .dlabnav {
            overflow: visible;
			z-index:2;
            .nav-text,
			.badge{
                display: none;
            }

            .slimScrollDiv,
            .dlabnav-scroll {
                overflow: visible !important;
            }
            .nav-user{
                padding: 0.6875rem;
                .media-body{
                    display: none;
                }
            }
			.header-profile2{
				.dropdown-menu.show{
					width: 10rem;
  					min-width: 10rem;
				}
			}
            .metismenu {
               li {
			   
                    a {
						 svg{
							margin-#{$dlab-pos-right}:0;
						 }
						 i{
							height:auto;
							width:auto;
							line-height:1;
							margin:0;
						 }
                    }

                    &>ul {
                        position: absolute;
                        #{$dlab-pos-left} : 0;
                        top: 2.9375rem;
                        width: 11.875rem;
                        z-index: 1001;
                        display: none;
                        padding-#{$dlab-pos-left}: 0.0625rem;
                        box-shadow: 0rem 0rem 2.5rem 0rem rgba(82, 63, 105, 0.1);
                        height: auto !important;
						border-radius: $radius;
						background: var(--card);
                    }
                }
                &>li {
					padding: 0.125rem 1.25rem;
                    &>a {
						border-radius: $radius;
						text-align: center;
						line-height: 1;
						transition: all 0.5s;
						-moz-transition: all 0.5s;
						-webkit-transition: all 0.5s;
						-ms-transition: all 0.5s;
						-o-transition: all 0.5s;
						
						& > i{
						padding:0;
						font-size:1.375rem;
						}
                        &.has-arrow {
                            &:after {
                                display: none;
                            }
                        }
						.badge-xs{
							&.style-1{
								margin-#{$dlab-pos-left}:-5px;
								margin-top:5px;
							}
						}
                    }
                }
                .nav-label,
                .nav-badge {
                    display: none;
                }
            }
			.header-info2{
				justify-content: center;
				margin: 10px 12px 0;
				padding: .35rem;
				img{
					margin-#{$dlab-pos-right}: 0;
				}
			}
			.sidebar-info{
				display:none!important;	
			}
			.header-profile2{
				margin:0 0.5rem;	
			}
        }
    }

    [data-sidebar-style="mini"][data-layout="vertical"] {
        .dlabnav {
			position: absolute ;
            .metismenu {

                li {
                    &:hover>ul {
                        display: block;
                    }
                }

                &>li {
					
                    &:nth-last-child(-n + 1) {
                        &>ul {
                            bottom:0;
                            top: auto !important;
							&:after{
								top:auto;
								bottom:1.25rem;
							}
                        }
                    }

                    @include respond('tab-land') {
                        &:nth-last-child(-n + 1) {
                            &>ul {
                                bottom: 0;
                                top: auto !important;
                            }
                        }
                    }

                    &>ul {
                        overflow: visible;
						
						&:after{
							content:none;
						}

                        li:hover {
                            ul {
                                padding: 0.625rem 0;
								width: 13rem;
								#{$dlab-pos-left} : 13rem;
								top: -0.625rem;
								border: 0;
								margin: 0;
								&:after{
									content:none;
								}			
                            }
                        }
                    }
					&.mm-active {
						&>a {
							background: var(--rgba-primary-1);
							color:$white;
							i{
								color:var(--primary);
							}
						}
					}
                    &:hover {


                        &>a {
                           background: var(--rgba-primary-1);
							color:var(--primary);
							position: unset;
							i{
								color:var(--primary);
							}	
                        }
                        &>ul {
                            height: auto !important;
                            overflow: visible;
							border:0;
							margin-#{$dlab-pos-left}:0;
							#{$dlab-pos-left} : var(--dz-sidebar-width);
							width: 13rem;
							border-radius:$radius;
							border:0;
							padding: 0.625rem 0;
							top: 0;
							
							@at-root [data-theme-version="dark"]#{&} {
								box-shadow: 0rem 0rem 2.5rem 0rem rgba(82, 63, 105, 0.1);
							}
							a {
                                padding: 0.375rem 1.25rem 0.375rem 1.25rem;
                               
							    &:before{
									content:none;
								}
								&.has-arrow:after{
									#{$dlab-pos-right} :  1.25rem
								}
                            }
							
                            ul {
								
								
								a {
									padding: 0.375rem 1.25rem 0.375rem 1.25rem;
									margin-#{$dlab-pos-left}: -0.1rem;
									&:before{
										content:none;
									}
								}
                            }
                        }
                    }
                }
            }
        }
    }
}