.widget-stat {
    .media {
        padding: 0px 0;
        align-items: center;
        &>span {
            background: #F5F7FB;
            height: 60px;
            width: 60px;
            border-radius: 50px;
            padding: 10px 12px;
            border: 1px solid #E6E8ED;
            font-size: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $l-ctd;
            ;
        }
        @include custommq($min: 576px) {}
        .media-body {
            p {
                text-transform: uppercase;
                font-weight: 500;
                font-size: 1rem;
            }
            h4 {
                font-size: 24px;
                color: var(--primary) !important;
                display: inline-block;
            }
            span {
                margin-#{$dlab-pos-left}: 10px;
                border-radius: 30px;
                font-weight: 400;
                font-size: 12px;
                padding: 3px 10px;
				
                &.badge-success {
                    background: #96FFAD;
                    color: #107125;
                }
                &.badge-danger {
                    background: #FFE1E1;
                    color: #c52424;
                }
            }
        }
    }
	&[class*="bg-"] .progress{
		background-color:rgba(255,255,255,0.25) !important;
	}
}
.dlab-widget-1{
    .card {
        background: #FFE7DB;
        .card-body {
            p {
                color: #f87533;
            }
        }
    }
	.icon{
		float: #{$dlab-pos-right};
		width: 50px;
		height: 50px;
		display: flex;
		align-items:center;
		justify-content:center;
		border-radius: 6px;
		font-size: 28px;
	}
}
.bgl-primary{
    background: var(--rgba-primary-1);
    border-color: var(--rgba-primary-1);
    color: var(--primary);
}

.bgl-secondary{
    background: lighten($secondary, 30%);
    border-color: lighten($secondary, 30%);
    color: var(--secondary);
}
.bgl-success{
    background: lighten($success, 32%);
    border-color: lighten($success, 32%);
    color: $success;
}

.bgl-warning{
    background: lighten($warning, 30%);
    border-color: lighten($warning, 30%);
    color: $warning;
}

.bgl-danger{
    background: lighten($danger, 32%);
    border-color: lighten($danger, 32%);
    color: $danger;
}
.bgl-info{
    background: lighten($info, 30%);
    border-color: lighten($info, 30%);
    color: $info;
}

.bgl-dark{
    background: lighten($dark, 60%);
    border-color: lighten($dark, 60%);
    color: $dark;
}
.bgl-light{
    background: $light;
    border-color: $light;
    color: $dark;
}
.overlay-box{
	position:relative;
	z-index:1;
	&:after{
		content:"";
		width:100%;
		height:100%;
		#{$dlab-pos-left} : 0;
		top:0;;
		position:absolute;
		opacity:0.95;
		background:var(--primary);
		z-index: -1;
	}
}
.rating-bar{
    font-size: 13px;
}