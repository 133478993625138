.fix-wrapper {
  min-height: 100vh;
  display: flex;
  padding: 30px 0;
  align-items: center;
}

.welcome-content {
  background: url("./../images/1.jpg");
  background-size: cover;
  background-position: center;
  height: 100%;
  padding: 75px 50px;
  position: relative;
  z-index: 1;
  border-top-#{$dlab-pos-left}-radius: 5px;
  border-bottom-#{$dlab-pos-left}-radius: 5px;
  &::after {
    content: "";
    position: absolute;
    #{$dlab-pos-left}: 0;
    #{$dlab-pos-left}: 0;
    top: 0;
    bottom: 0;
    background: var(--primary);
    opacity: 0.75;
    z-index: -1;
    border-top-#{$dlab-pos-left}-radius: 5px;
    border-bottom-#{$dlab-pos-left}-radius: 5px;
  }
  .welcome-title {
    color: $white;
    font-weight: 500;
    font-size: 20px;
  }
  p {
    color: $white;
  }

  .brand-logo {
    a {
      display: inline-block;
      margin-bottom: 20px;
      font-weight: 700;
      color: $white;
      font-size: 20px;
      img {
        width: 10px;
      }
    }
  }

  .intro-social {
    position: absolute;
    bottom: 75px;
    ul {
      margin-bottom: 0;
      li {
        display: inline-block;
        a {
          color: $white;
          font-size: 1rem;
          padding: 0px 7px;
        }
      }
    }
  }
}
