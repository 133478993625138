.sidebar-right-trigger {
	position: absolute;
	z-index: 9;
	top: 14.75rem;
	#{$dlab-pos-right} :  100%;
	background-color: var(--primary);
	color: $white;
	display: inline-block;
	height: 3rem;
	width: 3rem;
	text-align: center;
	font-size: 1.75rem;
	line-height: 3rem;
	border-radius: 0;
	border-top-#{$dlab-pos-left}-radius : $radius;
	border-bottom-#{$dlab-pos-left}-radius : $radius;
	box-shadow: -0.3125rem 0.1875rem 0.3125rem 0rem rgba(119, 119, 119, 0.15);
	
	@include custommq ($max:64rem){
		display:none;
	}
	&:hover{
		color:$white;	
	}
}

.sidebar-right {
    #{$dlab-pos-right} :  -50rem;
    position: fixed;
    top: 0;
    width: 50rem;
    background-color: var(--card);
    margin-top: 3.5rem;
    transition: all .5s ease-in-out;
	border-radius: $radius;
	z-index: 9999;
	.bg-label-pattern{
		background: transparent;
		background-image: url('../images/pattern/pattern5.png');
		background-size: 130%;
	}
	.bootstrap-select{
		height:3rem;
		border-radius: 0.375rem;
		.btn{
		    padding:0.75rem 0.9375rem;
			font-size:0.9375rem;
			border-color:#d1d1d1;
			border-radius: 0.375rem;
		}
	}
    .note-text{
		padding: 20px;
		font-size: 0.875rem;
		background:var(--card);
	}
	.sidebar-close-trigger{
		position:absolute;
		z-index: 2;
		font-size: 1.75rem;
		top:0;
		#{$dlab-pos-right} : -3rem;
		height: 3rem;
        width: 3rem;
		line-height:3rem;
		text-align:center;
		background:var(--text-dark);
		color: var(--card);	
	}
	

    &.show {
        #{$dlab-pos-right} :  5.25rem;
		box-shadow: 0rem 0rem 3.125rem rgba(0 ,0 ,0 ,0.2);
        z-index: 9999;
		
		.bg-overlay{
			position:fixed;
			width:100%;
			cursor:pointer;
			height:100%;
			top:0;
			#{$dlab-pos-left} :0;
			background:rgba($black,0.2);
		}
    }
	.card-tabs{
		.nav-tabs {
			justify-content: space-between;
			position: sticky;
			top: 0;
			width:100%;
			background-color: var(--card);
			z-index: 2;
			border-bottom: 4px solid var(--rgba-primary-1);
			.nav-item {
				margin-bottom: 0;
				flex: 1;
				.nav-link {
					border: 0;
					font-size: 1.125rem;
					position: relative;
					text-align: center;
					background-color: var(--card); 
					border-radius:0;
					margin:0;
					&.active {
						background: var(--rgba-primary-1);
						color: var(--text-dark);
					}
				}
			}
		}
	}
	.sidebar-right-inner > h4{
			padding: 0.625rem 1.25rem;
			display: flex;
			justify-content: space-between;
			align-items: center;
			color: var(--text-dark);
			background: var(--card);
			margin: 0rem 0rem 0rem 0rem;
	}

    .tab-content {
        padding: 1.25rem 1.25rem 0;
		background:var(--card);
        .tab-pane {

            .admin-settings {
				.row > div {
					margin-bottom: 1.25rem;
				}
                p {
                    color: var(--text);
					font-weight: 500;
					margin-bottom: 0.5rem;
					font-size: 1rem;
                }

                //All color switcher scss goes here
                input[type="radio"] {
                    display: none;

                    + label {
                        display: inline-block;
                        width: 2.1875rem;    
                        height: 2.1875rem;
                        cursor: pointer;
                        transition: all .1s ease;
						border-radius: 0.25rem;
						margin-#{$dlab-pos-right}: 0.3125rem;
						margin-bottom: 0.1875rem;
                    }

                    &:checked {
                        + label {
                            position: relative;

                            &::after {
                                height: 2.0625rem;
                                width: 2.0625rem;
                                #{$dlab-pos-left} : -0.25rem;
                                top: -0.25rem;
                                content: "";
                                position: absolute;
                                background-color: inherit;
								border-radius: 0.375rem;
								opacity: 0.4;
                            }
                        }
                    }
                }
            }

        }
    }

   
    #nav_header, 
    #header, 
    #sidebar,
	#primary{


        @each $name, $color in $theme_backgrounds {
            &_#{$name} {
                + label {
                    background-color: $color;            
                }
            }
        }

        &_color_1 {
            + label {
                border: 0.0625rem solid #c4c4c4;
            }
        }
    }
	
	&.style-1{
		height:100vh;
		width:15.625rem;
		margin-top:0;
		#{$dlab-pos-right} : -15.625rem;
		.sidebar-right-inner{
			background:var(--card);
		}
		.sidebar-right-trigger {
			top:12.4rem;
		}
		&.show{
			#{$dlab-pos-right} : 0;
			.sidebar-right-trigger{
				display:block;
			}
		}
	}
	[for=header_color_1], 
	[for=nav_header_color_1], 
	[for=primary_color_1], 
	[for=sidebar_color_1] {
		background: transparent;
		background-size: 130%;
		background-image: url('../images/pattern/pattern5.png');
	}
}


@keyframes bounce {
	0% {
        transform: translateX(-8%);;
        -webkit-transform: translateX(-8%);; 
        }
	50% {
        transform: translateX(8%);;
        -webkit-transform: translateX(8%);; 
        }
	100% {
        transform: translateX(-8%);; 
        -webkit-transform: translateX(-8%);; 
        }
}

@-webkit-keyframes bounce {
	0% {
        transform: translateX(-8%);;
        -webkit-transform: translateX(-8%);; 
        }
	50% {
        transform: translateX(8%);;
        -webkit-transform: translateX(8%);; 
        }
	100% {
        transform: translateY(-8%);; 
        -webkit-transform: translateY(-8%);; 
        }
}
.nice-select.wide{
	@include respond ('laptop'){
		line-height:2rem;
	}
}