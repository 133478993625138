.photo-content{
    position: relative;
    .cover-photo{
        background: url(../images/profile/cover.jpg);
        background-size: cover;
        background-position: center;
        min-height: 250px;
        width: 100%;

    }

}
.profile .profile-photo {
	max-width: 100px;
	position: relative;
	z-index: 1;
	margin-top: -40px;
	margin-#{$dlab-pos-right}: 10px;
	
	@include respond('tab-land') {
		
	}
	@include respond('phone') {
		width: 80px;
		margin-#{$dlab-pos-left}: auto;
		margin-#{$dlab-pos-right}: auto;
		margin-bottom:20px;
	}
}
.profile-info{
    padding: 15px 20px;
    // margin-bottom: 30px;
	@include respond('phone') {
		padding: 0 0 20px;
		text-align:center;
	}
    h4 {
        color: $l-ctd!important;
    }

    h4.text-primary {
        color: var(--primary) !important;
    }

    p {
        color: $l-ctl;
    }

    .prf-col{
        min-width: 250px;
        padding: 10px 50px 0;
    }
}
.profile-interest{
    .row{
        margin: 0 -.70px;
        .int-col{
            padding: 0 .70px;
            .interest-cat{
                margin-bottom: 1.40px;
                position: relative;
                display: block;
                &:after{
                    background: $black;
                    bottom: 0;
                    content: "";
                    #{$dlab-pos-left} : 0;
                    opacity: 0.5;
                    position: absolute;
                    #{$dlab-pos-right} : 0;
                    top: 0;
                    z-index: 0;
                }
                p{
                    position: absolute;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    padding: 5px;
                    #{$dlab-pos-left} : 0;
                    margin: 0;
                    z-index: 1;
                    color:white;
                    font-size: 1.20px;
                }
            }
            
        }
    }
}
.profile-tab{
    .nav-item{
        .nav-link{
            font-size: 1rem;
            margin-#{$dlab-pos-right}: 30px;
            transition: all 0.5s ease-in-out;
            border: none;
            border-bottom: .20px solid transparent;
            color: $l-ctl;

            &:hover, &.active{
                border: 0;
                background: transparent;
                background: transparent;
                border-bottom: .20px solid var(--primary);
                color: var(--primary);
            }

        }
        
    }
}
.profile-info{
	display:flex;
	@include respond('phone') {
		display:block;
	}
	.profile-details {
		display: flex;
		width: 100%;
		@include respond('phone') {
			display:block;
			.dropdown{
				position: absolute;
				top: 30px;
				#{$dlab-pos-right} : 30px;
			}
		}
	}
}

.post-input{
    margin-bottom: 30px;
    .form-control{
        height: 75px;
        font-weight: 400;
        margin: 15px 0;
    }
    
}  
.profile-uoloaded-post{
    
    img{
        margin-bottom: 20px;
    }
    a{
        h4{
            margin-bottom: 10px;
            color: $l-ctd;
        }
    }
}
