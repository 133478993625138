.email-left-box {
    margin-bottom: 1.25rem;
   @include respond ('phone'){
       margin-top: 1.25rem;
   }
}
.intro-title{
       background: rgba($primary, .1);
       padding: 1rem;
       margin: 1.875rem 0 1.25rem 0;
       border-radius: 0.75rem 0.75rem 0 0;
       h5{
           margin-bottom: 0;
           color: #6a707e;
           font-size: 0.875rem;

           i{
               font-size: 0.75rem;
               position: relative;
               bottom: 0.063rem;
           }
       }

       i{
           color: $primary;

       }
   }


@include respond ('phone'){
   .email-left-body {
     width: 18rem;
     position: fixed;
     height: 100vh;
     z-index: 2;
     overflow-y: scroll;
     transition: all 0.5s;
     left: -22rem;
     top: 0;
     z-index: 999; }
     .email-left-body.active {
       left: 0;
       background: #fff;
       transition: all 0.5s; 
       overflow-y: scroll;
       
         @at-root [data-theme-version="dark"] & {
             background:$d-bg;
         }
     } 
 }
 .toolbar {
   .btn-group {
       .btn {
                                
           border: 0;	
       }
       input{
           position: relative;
           top: 0.125rem;
       }
   }
}
.read-content {
   textarea {
       height: 9.375rem;
       padding: 0.938rem 1.25rem;
   }

   &-email{
       font-size: 0.875rem;
   }

   h5{
       color: #6a707e;
   }

   p{

       strong{
           color: #6a707e;
       }
   }

   &-body{

       p{
           margin-bottom: 1.875rem;
       }
   }

   &-attachment{
       padding: 0.5rem 0;

       h6{
           font-size: 1.125rem;
           i{
               padding-right: 0.3125rem;
           }
       }

       .attachment{

           & > div:not(:last-child){
               border-right: 0.063rem solid #DDDFE1;
           }
       }
   }
}  

.compose-content {
   .wysihtml5-toolbar {
       border-color: $l-border;
   }
   .dropzone {
       background: $l-bg!important;
   }

   h5{
       font-size: 1.0625rem;
       color: #6a707e;

       i{
           font-size: 1.125rem;
           transform: rotate(90deg);
       }
   }

   .dropzone{
       border: 0.063rem dashed #DDDFE1;
       min-height: 13.125rem;
       position: relative;
       border-radius: 0.5rem;

   }
}

.email-list {
   display: block;
   padding-left: 0;

   .message {
       position: relative;
       display: block;
       height: 3.125rem;
       line-height: 3.125rem;
       cursor: default;
       transition-duration: 0.3s;
       a {
           color: $l-ctl;
       }

       &-single{
           .custom-checkbox{
               margin-top: 0.125rem;
           }

           i{
               color: $muted;
               font-size: 1.125rem;
               padding-left: .4rem;
           }


       }


       &:hover {
           transition-duration: 0.05s;
           background: rgba(152, 166, 173, 0.15);
       }

       .col-mail {
           float: left;
           position: relative;
       }

       .col-mail-1 {
           width: 5.625rem;

           .star-toggle {
               display: block;
               float: left;
               margin-top: 1.125rem;
               font-size: 1rem;
               margin-left: 0.3125rem;
           }

           .email-checkbox {
               display: block;
               float: left;
               margin: 0.9375rem 0.625rem 0 1.25rem;
           }

           .dot {
               display: block;
               float: left;
               border: .4rem solid transparent;
               border-radius: 6.25rem;
               margin: 1.375rem 1.625rem 0;
               height: 0;
               width: 0;
               line-height: 0;
               font-size: 0;
           }
       }

       .col-mail-2 {
           position: absolute;
           top: 0;
           left: 5.625rem;
           right: 0;
           bottom: 0;
           float: left;
           .subject {
               position: absolute;
               top: 0;
               left: 0;
               right: 5.5rem;
               text-overflow: ellipsis;
               overflow: hidden;
               white-space: nowrap;
           }

           .date {
               position: absolute;
               top: 0;
               right: 0;
           }
       }
   }
}

.email-checkbox {
   cursor: pointer;
   height: 0.9375rem;
   width: 0.9375rem;
   position: relative;
   display: inline-block;
   border-radius: .1rem;
   position: relative;
   top: 0.3125rem;
   box-shadow: inset 0 0 0 .1rem $l-ctl;

   input {
       opacity: 0;
       cursor: pointer;

       &:checked {
           label {
               opacity: 1;
           }
       }
   }

   label {
       position: absolute;
       top: .3rem;
       left: .3rem;
       right: .3rem;
       bottom: .3rem;
       cursor: pointer;
       opacity: 0;
       margin-bottom: 0 !important;
       transition-duration: 0.05s;
   }
}

.mail-list {

   a {
       vertical-align: middle;
       padding: 0.625rem 0.9375rem;
       display: block;
       background: transparent;
       color: $l-ctd;
       font-weight: 600;
   }

   .list-group-item{
       color: #6a707e;
       padding: 0.75rem 1.0625rem;
       &:hover{
           color:$primary;
       }

       i{
           font-size: 1rem;
           padding-right: 0.625rem;
           color: #cccccc;
       }

       &.active{
           color: $white;
           background-color:$primary;
           border-color:$primary;
           i{
               color: $white;
           }
       }
       &:first-child{
           border-top-left-radius: $radius;
           border-top-right-radius: $radius;
       }
       &:last-child{
           border-bottom-left-radius: $radius;
           border-bottom-right-radius: $radius;
       }
   }
}

.chat-wrap{
   padding: 1.0625rem 1.875rem;
   .media{
       .media-body{
           h6{
               font-size: 1.0625rem;
               color: #6a707e;
           }
           p{
               font-size: 0.875rem;
           }
       }
   }
}

.email-filter{
   @media (min-width: 40.5rem){
       padding-left: 1.25rem;
   }
   @media(min-width: 106.25rem){
       padding-left: 1.875rem;
   }
   .input-group{
       &-prepend{
           
           i{
               font-size: 0.875rem;
               color: $muted;
           }

           .input-group-text{
               border: 0;
               border-bottom: 0.063rem solid #DDDFE1 !important;
               background: transparent;
           }
       }

       .form-control{
           padding: 0 0 0 0.3125rem;
           border: 0;
           font-size: 0.875rem;
           height: 1.875rem;
           color: $muted;
           border-bottom: 0.063rem solid #DDDFE1;

           &::placeholder{
               font-size: 0.875rem;
               color: $muted;
           }
       }

       & > .form-control{
           min-height: 1.875rem;
       }
   }
}

.single-mail{
   display: block;
   padding: 1.5625rem 0;

   .media{
       padding-left: 1.25rem;
       padding-right: 1.25rem;

       @media(min-width: 106.25rem){
           padding-left: 1.875rem;
           padding-right: 1.875rem;
       }

       img{
           width: 3.438rem;
           height: 3.438rem;
           border-radius: 50%;
           margin-right: 0.9375rem;

           @media(min-width: 106.25rem){
               margin-right: 1.875rem;
           }
       }

       &-body{

           h6{
               color: #abafb3;
           }

           h4{
               font-size: 1rem;
               color: #6a707e;

               button{

                   i{
                       font-size: 1.125rem;
                       color: #abafb3;
                       font-weight: bold;
                       transform: rotate(90deg);
                   }
               }
           }

           p{
               font-size: 0.875rem;
               color: #abafb3;
           }
       }
   }

   &.active{
       background: $primary;
       h6,h4,p,i{
           color: $white !important;
       }
   }
}


.email-tools-box{
   display:inline-block;
   width:2.5rem;
   height:2.5rem;
   line-height:2.5rem;
   text-align:center;
   background-color:$primary-light;
   border-radius:$radius;
   color:$primary;
   
   &.active{
       background-color:$primary;
       color:$white;
   }
   
   display:none!important;
   @include respond ('phone'){
       display:inline-block!important;
       margin-left:auto;
   }
   &.active{
       &:before{
           content: '';
           display: block;
           position: fixed;
           top: 0;
           left: 0;
           width: 100vh;
           height: 100vh;
           background-color: #000;
           opacity: 0.2;
           z-index: 4;
       }
   }
}

.icon-warning{
   i{
       color:$danger !important;
   }
}
.icon-primary{	 	   
   i{
       color:$primary !important;	
   }
}
.icon-success{
   i{
       color:$success !important;
   }
}
.icon-dpink{  
   i{
       color:$info !important;	
   }
}

.email-list .message-single i.yellow{
   color:$warning !important;
}
.dropzone .dz-message {
   margin: 4rem 0 !important;
   text-align:center;
}

.pull-end {
   float: right;
}

.dropzone {
   &.compose {
       .rs-uploader-trigger {           
           text-align: center;
           margin: 7rem 0;
           .rs-uploader-trigger-btn{
               background: transparent;
               border: none;
           }
       }
   }
}