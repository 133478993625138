.nav-header {
    height: var(--dz-header-height);
    width: var(--dz-sidebar-width);
    display: inline-block;
    text-align: left;
    position: absolute;
    top:0;
    background-color:var(--nav-headbg);
    transition: all .2s ease;
    z-index: 20;
	
    .logo-abbr {
        max-width: 3.7375rem;
    }
    .logo-compact {
        display: none;
    }
	
    .brand-logo {
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;
        font-size: 1.125rem;
        color: $white;
        text-decoration: none;
        font-weight: 700;
    }
    .brand-title {
		margin-#{$dlab-pos-left}: 0.9375rem;
		max-width: 8.75rem;
        @include respond('phone-land'){
            display: none;
        }
    }
	.rect-primary-rect{
		fill:var(--primary);
	}
    @include respond('phone-land'){
        width: 5rem;
    }
}

.nav-control {
    cursor: pointer;
    position: absolute;
    #{$dlab-pos-right} :  -4rem;
    text-align: center;
    top: 55%;
    transform: translateY(-50%);
    z-index: 9999;
    font-size: 1.4rem;
    padding: 0.125rem 0.5rem 0;
    border-radius: 0.125rem;
    @include respond('laptop') {
		#{$dlab-pos-right} :  -3.444rem;
	}
    @include respond('phone-land') {
        #{$dlab-pos-right} :  -3rem;
    }
	@include respond('phone') {
        #{$dlab-pos-right} :  -3rem;
    }
}

////////////////////
// Humbuger Icon
////////////////////
.hamburger {
    display: inline-block;
    #{$dlab-pos-left} : 0rem;
    position: relative;
    top: 0rem;
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
    width: 1.625rem;
    z-index: 999;
}

.hamburger .line {
    background: var(--text-gray);
    display: block;
    height: 2px;
    border-radius: 0.1875rem;
    margin-top: 0.375rem;
    margin-bottom: 0.375rem;
    margin-#{$dlab-pos-right}: auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.hamburger .line:nth-child(1),.hamburger .line:nth-child(3) {
    width: 0.9375rem;
}

.hamburger .line:nth-child(2) {
    width: 1.625rem;
}
.hamburger:hover {
    cursor: pointer;
}

.hamburger:hover .line {
    width: 1.625rem;
}


.hamburger.is-active .line:nth-child(1),
.hamburger.is-active .line:nth-child(3) {
    width: 0.625rem;
    height: 0.125rem;
}

.hamburger.is-active .line:nth-child(2) {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    width: 1.375rem;
    height: 0.125rem;
}

.hamburger.is-active .line:nth-child(1) {
    -webkit-transform: translateY(4px) translateX(12px) rotate(45deg);
    transform: translateY(4px) translateX(12px) rotate(45deg);
}

.hamburger.is-active .line:nth-child(3) {
    -webkit-transform: translateY(-4px) translateX(12px) rotate(-45deg);
    transform: translateY(-4px) translateX(12px) rotate(-45deg);
}

[data-header-position="fixed"],
[data-sidebar-position="fixed"][data-layout="vertical"],
[data-sidebar-position="fixed"][data-header-position="fixed"]{
    .nav-header{
        position: fixed;
    }
}
[data-sidebar-style="compact"][data-layout="vertical"] {
    .nav-header {
        .brand-title {
            display: none;
        }
        .logo-compact {
            max-width: 4.6875rem;
        }
        .brand-logo{
            justify-content: center;
        }
    }
}
[data-sidebar-style="modern"][data-layout="vertical"] {
    .nav-header {
        .brand-title {
            display: none;
        }
        .brand-logo{
            justify-content: center;
        }
        .logo-compact {
            display: none;
        }
    }
}
