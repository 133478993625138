.easy-pie-chart {
  position: relative;
  text-align: center;

  .inner {
    position: absolute;
    #{$dlab-pos-left}: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: inline-block;
  }

  img {
    width: 75px;
    height: 75px;
    border-radius: 50%;
  }

  canvas {
    display: block;
    margin: 0 auto;
  }
}

.doughnut-chart {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .doughnut-chart__content {
    margin-left: 5rem;

    div {
      display: flex;

      padding-top: 1rem;
      flex-wrap: wrap;
      span {
        width: 50%;
        padding: 1rem;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    text-align: center;
    .doughnut-chart__content {
      margin-left: 0;
    }
  }
}
