#chart_widget_4 {
    height: 255px !important;
}

#chart_widget_5 {
    .ct-series-a .ct-line, 
    .ct-series-a .ct-point {
        stroke: $white;
    }

    .ct-line {
        stroke-width: 2px;
    }

    .ct-point {
        stroke-width: 5px;
    }

    .ct-series-a .ct-area {
        fill: $white;
    }

    .ct-area {
        fill-opacity: .8;
    }
}

#chart_widget_6 {
    .ct-series-a .ct-line, 
    .ct-series-a .ct-point {
        stroke: var(--primary);
    }

    .ct-line {
        stroke-width: 2px;
    }

    .ct-point {
        stroke-width: 5px;
    }

    .ct-series-a .ct-area {
        fill: var(--primary);
    }

    .ct-area {
        fill-opacity: .5;
    }
}

#chart_widget_8 {
    height: 255px;

    .ct-series-a .ct-line, 
    .ct-series-a .ct-point {
        stroke: var(--primary);
    }

    .ct-line {
        stroke-width: 2px;
    }

    .ct-point {
        stroke-width: 5px;
    }

    .ct-series-a .ct-area {
        fill: var(--primary);
    }

    .ct-area {
        fill-opacity: .5;
    }
}

#chart_widget_9, #chart_widget_10 {
    height: 250px !important;
}

#chart_widget_11, #chart_widget_12, #chart_widget_13 {
    .ct-slice-donut {
        stroke-width: 25px !important;
    }
}


#chart_widget_11 {
    height: 270px !important;
}

#chart_widget_17 {
    height: 150px !important;
}


.chart_widget_tab_one {
    .nav-link {
        border: 1px solid #ddd;

        &.active {
            background-color: var(--primary);
            border: 1px solid var(--primary);
            color: $white;

            &:hover {
                border: 1px solid var(--primary);
            }
        }

        &:hover {
            border: 1px solid #ddd;
        }
    }
}

.ccc-widget {
    &>div {
        @at-root [data-theme-version="dark"] & {
            background: $dark-card !important;
            border-color: $d-border !important;
        }
    }
}

.widget-cart-tab {
    .nav-link{
        color: #ffffff80;
        margin-right: 30px;
        padding-left: 0;
        padding-right: 0;
        &.active{
            background: #0000;
            border-color: $white;
            color: $white;
        }
        &:hover{
            // background-color: $white;
            background: #0000;
            color: $white;
            border-color: $white;
        }
    }       
}


.list-group-flush {
    &.style-4{
        .list-group-item{
            padding: 1rem 1.5rem;
        }
    }
}