//to ignore cli warning about deprecated mixin
$enable-deprecation-messages: false;
$ignore-warning: true;

// Custom class not bootstrap
$fn: "HelveticaNeue"; // fn = font normal
$fm: "HelveticaNeueMed"; // fm = font Medium

// Body
$body-bg: #f7f8fa;
$body-color: #444;

// Typography
$font-family-base: "Roboto", sans-serif;
$headings-font-weight: 500;
// $headings-font-family:        $fm;

$font-size-base: 0.937rem !default;
$font-weight-base: 400 !default;
// $line-height-base: 1.6;
$h1-font-size: 2.25rem;
$h2-font-size: 1.875rem;
$h3-font-size: 1.5rem;
$h4-font-size: 1.125rem;
$h5-font-size: 1rem;
$h6-font-size: 0.875rem;

//
// Color system
//
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$blue: #1367c8;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #ee3232;
$orange: #fd7e14 !default;
$yellow: #fffa6f;
$green: #297f00;
$teal: #20c997 !default;
$cyan: #3065d0;

$grays: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
  (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900,
  ),
  $grays
);

$muted: #666666;
$text-muted: #666666;
$mine-shaft: #262626;
$ebony-clay: #232833;

$colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(
  (
    "blue": $blue,
    "indigo": $indigo,
    "purple": $purple,
    "pink": $pink,
    "red": $red,
    "orange": $orange,
    "yellow": $yellow,
    "green": $green,
    "teal": $teal,
    "cyan": $cyan,
    "white": $white,
    "gray": $gray-500,
    "gray-dark": $gray-800,
  ),
  $colors
);

$primary: #1367c8 !default;
$secondary: #673bb7 !default;
$success: #7ed321 !default;
$info: #50e3c2 !default;
$warning: #ffaa16 !default;
$danger: #ff1616 !default;
$light: #dadada !default;
$dark: #454545;
$profileBackground: #9f95db;

:root {
  --primary: #{$primary};
  --secondary: #{$secondary};
  --primary-hover: #{darken($primary, 10)};
  --primary-light: #{lighten($primary, 10)};
  --primary-dark: #{darken($primary, 10)};
  --rgba-primary-1: #{rgba($primary, 0.1)};
  --rgba-primary-2: #{rgba($primary, 0.2)};
  --rgba-primary-3: #{rgba($primary, 0.3)};
  --rgba-primary-4: #{rgba($primary, 0.4)};
  --rgba-primary-5: #{rgba($primary, 0.5)};
  --rgba-primary-6: #{rgba($primary, 0.6)};
  --rgba-primary-7: #{rgba($primary, 0.7)};
  --rgba-primary-8: #{rgba($primary, 0.8)};
  --rgba-primary-9: #{rgba($primary, 0.9)};
  --font-family-base: #{$font-family-base};
  --font-family-title: #{$font-family-base};

  //  sidebar header
  --dz-sidebar-width: 16.5rem;
  --dz-header-height: 4.5rem;
  --dz-sidebar-pos: absolute;
  --dz-header-pos: relative;
  --dz-side-nav-padding: 0.85rem 1rem;
  --dz-side-nav-margin: 0 0;
  --dz-side-nav-size: 0.8375rem;
  --dz-side-ico-size: 1.2rem;
  --dz-side-ico-height: auto;
  --dz-side-ico-width: auto;
  --dz-side-sub_nav-padding: 0.4rem 1rem 0.4rem 3.5rem;
  --dz-side-sub_nav-size: 0.85rem;
  --dz-head-title-size: 1.25rem;
  --dz-side-menu-direction: column;
  --dz-side-menu-padding: 0 0;
  --dz-side-profile: block;
  --dz-side-info: block;
  --dz-side-copyright: block;

  // list
  --dz-list-space-x: 1.25rem;
  --dz-list-space-y: 0.5rem;
  --dz-list-size: 0.875rem;
}

$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "dark": $dark,
  ),
  $theme-colors
);

// $base-font-size   : 0.8125rem !default;
$heading-primary-size: 1.8rem;
$sub-heading-size: 1.6rem !default;

$grid-gutter-width: 30px;

$dropdown-lik-color: $body-color;

$border-color: #eeeeee;
$headings-color: #3d4465 !default;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440,
);
