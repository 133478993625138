header styles [direction="rtl"] {
  .header {
    padding: 0 0.9375rem;
    padding-#{$dlab-pos-right}: 17.1875rem;

    @include media-breakpoint-down(sm) {
      padding-#{$dlab-pos-right}: 3.75rem;
      padding-#{$dlab-pos-left}: 0;
    }

    .header-content {
      padding-#{$dlab-pos-left}: 0;
      padding-#{$dlab-pos-right}: 5.3125rem;

      @include custommq($max: 575px) {
        padding-#{$dlab-pos-right}: 4.5rem;
      }
      @at-root [data-sidebar-style="compact"]#{&} {
        padding-#{$dlab-pos-right}: 1.875rem;
      }
      @at-root [data-sidebar-style="modern"]#{&} {
        padding-#{$dlab-pos-right}: 5.3125rem;
      }
      @at-root [data-sidebar-style="overlay"]#{&} {
        padding-#{$dlab-pos-right}: 5.3125rem;
      }
    }

    .nav-control {
      right: 0.4375rem;
      #{$dlab-pos-left}: auto;
    }
  }
  .header-left {
    .search_bar {
      .dropdown-menu,
      .dropdown-menu.show {
        left: 40px !important;
        @include respond("phone") {
          left: -100px !important;
        }
      }
      .search_icon {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-#{$dlab-pos-right}-radius: 5rem;
        border-bottom-right-radius: 5rem;
        @include respond("phone") {
          border-radius: 5rem;
        }
      }
    }
  }

  &[data-layout="horizontal"] {
    .header {
      padding: 0;
      padding-#{$dlab-pos-right}: 16rem;

      .header-content {
        padding-#{$dlab-pos-right}: 1.875rem;
        padding-#{$dlab-pos-left}: 1.875rem;
      }
    }
  }
  &[data-layout="horizontal"][data-sidebar-style="mini"] {
    .header {
      padding-#{$dlab-pos-right}: 6rem;
    }
  }
  &[data-sidebar-style="mini"] {
    .header {
      padding: 0 0.9375rem;
      padding-#{$dlab-pos-right}: 3.75rem;
    }
  }

  &[data-sidebar-style="compact"] {
    .header {
      padding: 0 0.9375rem;
      padding-#{$dlab-pos-right}: 9.375rem;
    }
    &[data-layout="horizontal"] {
      .header {
        padding: 0;
        padding-#{$dlab-pos-right}: 16rem;
      }
    }
  }
  &[data-sidebar-style="modern"] {
    .header {
      padding: 0 0.9375rem;
      padding-#{$dlab-pos-right}: 9.375rem;
    }
    &[data-layout="horizontal"] {
      .header {
        padding: 0;
        padding-#{$dlab-pos-right}: 16rem;
      }
    }
  }
}
