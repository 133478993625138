
.widget-timeline{

	.timeline {
		list-style: none;
		position: relative;
		&:before {
			top: 20px;
			bottom: 0;
			position: absolute;
			content: " ";
			width: 3px;
			#{$dlab-pos-left} : 9px;
			margin-#{$dlab-pos-right}: -1.5px;
			background: $light;
			@at-root [data-theme-version="dark"] & {
				background: $d-border;
			}
			
		}
		> li {
			margin-bottom: 15px;
			position: relative;
			&:before {
				content: " ";
				display: table;
			}
			&:after {
				content: " ";
				display: table;
				clear: both;
			}
			> {
				.timeline-panel {
					border-radius: $radius;
					padding: 15px 20px;
					position: relative;
					display:block;
					margin-#{$dlab-pos-left}: 40px;
					border-width:1px;
					border-style:solid;
					span{
						font-size: 0.75rem;
						display: block;
						margin-bottom: 5px;
						opacity: 0.8;
						color: var(--text-dark);
						letter-spacing: 1px;
					}
					p{
						font-size: 0.875rem;
					}
					&:after{
						content: "";
						width: 10px;
						height: 10px;
						background: inherit;
						border-color: inherit;
						border-style: solid;
						border-width: 0px 0px 1px 1px;
						display: block;
						position: absolute;
						#{$dlab-pos-left} : -5px;
						transform: rotate(45deg);
						top: 15px;
					}
				}
				.timeline-badge {
					border-radius: 50%;
					height: 22px;
					#{$dlab-pos-left} : 0;
					position: absolute;
					top: 10px;
					width: 22px;
					border-width: 2px;
					border-style: solid;
					background:$white;
					padding:4px;
					@at-root [data-theme-version="dark"] & {
						background-color: $dark-card;
					}
					&:after{
						content:"";
						width:10px;
						height:10px;
						border-radius:100%;
						display:block;
					}
				}
			}
		}
	}
	
	.timeline-body > p {
		font-size: 12px;
	}
  
  .timeline-badge {
    &.primary{
		border-color:var(--rgba-primary-1);
		@at-root [data-theme-version="dark"] & {
			border-color: var(--rgba-primary-2);
		}
		&:after{
			background-color: var(--primary)!important;
			box-shadow:0 5px 10px 0 var(--rgba-primary-2);
		}
		& + .timeline-panel{
			background:var(--rgba-primary-1);
			border-color:var(--rgba-primary-1);
			@at-root [data-theme-version="dark"] & {
				border-color: transparent;
				background-color: var(--rgba-primary-2);
			}
		}
    }
    &.success{
		border-color:$success-light;
		@at-root [data-theme-version="dark"] & {
			border-color: $success-opacity;
		}
		&:after{
			background-color: $success !important;
			box-shadow:0 5px 10px 0 rgba($success, 0.2);
		}
		& + .timeline-panel{
			background:$success-light;
			border-color:$success-light;
			@at-root [data-theme-version="dark"] & {
				background-color: $success-opacity;
				border-color: transparent;
			}
		}
    }
    &.warning{
		border-color:$warning-light;
		@at-root [data-theme-version="dark"] & {
			border-color: $warning-opacity;
		}
		&:after{
			background-color: $warning !important;
			box-shadow:0 5px 10px 0 rgba($warning, 0.2);
		}
		& + .timeline-panel{
			background:$warning-light;
			border-color:$warning-light;
			@at-root [data-theme-version="dark"] & {
				background-color: $warning-opacity;
				border-color: transparent;
			}
		}
    }
    &.danger{
		border-color:$danger-light;
		@at-root [data-theme-version="dark"] & {
			border-color: $danger-opacity;
		}
		&:after{
		  background-color: $danger !important;
		  box-shadow:0 5px 10px 0 rgba($danger, 0.2);
		}
		& + .timeline-panel{
			background:$danger-light;
			border-color:$danger-light;
			@at-root [data-theme-version="dark"] & {
				background-color: $danger-opacity;
				border-color: transparent;
			}
		}
    }
	
    &.info{
		border-color:$info-light;
		@at-root [data-theme-version="dark"] & {
			border-color: $info-opacity;
		}
		&:after{
			background-color: $info !important;
			box-shadow:0 5px 10px 0 rgba($info, 0.2);
		}
		& + .timeline-panel{
			background:$info-light;
			border-color:$info-light;
			@at-root [data-theme-version="dark"] & {
				background-color: $info-opacity;
				border-color: transparent;
			}
		}
    }
    &.dark{
		border-color:$dark-light;
		@at-root [data-theme-version="dark"] & {
			border-color: $dark-opacity;
		}
		&:after{
		  background-color: $dark !important;
		  box-shadow:0 5px 10px 0 rgba($dark, 0.2);
		}
		& + .timeline-panel{
			background:$dark-light;
			border-color:$dark-light;
			@at-root [data-theme-version="dark"] & {
				background-color: $dark-opacity;
				border-color: transparent;
			}
		}
    }
  }
	
	&.style-1{
		.timeline-panel{
			background:transparent;
		}
		.timeline {
			.timeline-badge {
				&.timeline-badge{
					& + .timeline-panel{
						background: transparent !important;
						border: 0;
						border-#{$dlab-pos-left} : 0.3125rem solid;
						border-radius: 0;
						padding: 0.3125rem 0;
						padding-#{$dlab-pos-left}: 0.9375rem;
						padding-#{$dlab-pos-right}: 0.625rem;
						&:after{
							border:0;
							#{$dlab-pos-left} : -9px;
							width: 7px;
							height: 7px;
						}
					}
				}
				&.primary{
					& + .timeline-panel{
						border-color:var(--primary);
						&:after{
							background:var(--primary);
						}
					}
				}
				&.success{
					& + .timeline-panel{
						border-color:$success;
						&:after{
							background:$success;
						}
					}
				}
				&.warning{
					& + .timeline-panel{
						border-color:$warning;
						&:after{
							background:$warning;
						}
					}
				}
				&.danger{
					& + .timeline-panel{
						border-color:$danger;
						&:after{
							background:$danger;
						}
					}
				}
				&.info{
					& + .timeline-panel{
						border-color:$info;
						&:after{
							background:$info;
						}
					}
				}
				&.dark{
					& + .timeline-panel{
						border-color:$dark;
						&:after{
							background:$dark;
						}
					}
				}
			}
		}
	
	}  
  
}

  
  