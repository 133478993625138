[data-theme-version="dark"] {
    // background: $d-bg;
    // color: $d-ctl;
	
	--nav-headbg : #{$dark-card};
	--sidebar-bg : #{$dark-card};
	--headerbg : #{$dark-card};

    --card : #{$dark-card};
    --text-dark : #{$d-text-dark};
    --text-gray : #{$d-text-gray};
    --text : #{$d-text};
    --body-bg : #{$d-bg};
    --light : #{$d-bg};
    --border : #{$d-border};
    --bs-body-color :#{$d-text};
    --bs-heading-color : #{$d-text-dark};
    --bs-emphasis-color : #{$d-text-dark}; 
}