[data-sidebar-style="overlay"] {
	&[data-layout="vertical"]{

		--dz-side-sub_nav-padding : .5rem .5rem .5rem 3.5rem;
		--dz-side-nav-padding : .85rem 1.5rem;

	}
    .dlabnav {
        #{$dlab-pos-left} : -100%;
        @include transitionMedium;
		box-shadow: 0 0 0.625rem rgba(0, 0, 0, 0.2);
		.metismenu{
			& > li {
				padding: 0 0.5rem;
				& > a {
					color: #7f7184;
					border-radius: $radius;
					-webkit-transition: all 0.5s;
					-ms-transition: all 0.5s;
					transition: all 0.5s;
					i{
						line-height:1;
					}
				}
				&:hover > a{
					color:var(--primary);
					i{
						color:var(--primary);
					}
				}
				&.mm-active > a {
					color:var(--primary);
					i{
						color:var(--primary);
					}
				}
				
			}
			
		}
		
    }
    .content-body {
        margin-#{$dlab-pos-left}: 0;
    }
    .nav-header {
        position: absolute;
        .hamburger.is-active {
            #{$dlab-pos-left} : 0;
            .line {
                background-color: var(--primary);
            }
        }
    }
    .menu-toggle {
        .nav-header {
            position: absolute;
            #{$dlab-pos-left} : auto;
        }
        .dlabnav {
            #{$dlab-pos-left} : 0;
        }
    }
    .footer {
        padding-#{$dlab-pos-left}: 0;
    }
}

[data-sidebar-style="overlay"][data-header-position="fixed"] {
    .nav-header {
        position: fixed;
    }
}


[data-container="wide-boxed"][data-sidebar-position="fixed"][data-sidebar-style="overlay"][data-layout="vertical"],
[data-container="boxed"][data-sidebar-position="fixed"][data-sidebar-style="overlay"][data-layout="vertical"]{ 
	.dlabnav {
        position: fixed;
		#{$dlab-pos-left} : auto;
		width:0;
		overflow: hidden;
		transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1) 0s;
		& > div{
			width: var(--dz-sidebar-width);
			min-width: var(--dz-sidebar-width);
			#{$dlab-pos-right} :  var(--dz-sidebar-width);
			transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1) 0s;
		}
    }
	.menu-toggle{
		.dlabnav {
			width:var(--dz-sidebar-width);
			& > div{
				#{$dlab-pos-right} :  0;
			}
		}
	}	
}